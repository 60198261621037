/*
===== !!! ===== CAUTION ===== !!! =====

   Write theme color specific stuff
   into `app/css/.theme-styles.css`.

===== !!! ===== CAUTION ===== !!! =====
*/

/* #region custom properties (variables) */
:root {
  --primary: #0078d7;
  --success: #60a917;
  --warning: #e40e16;
  --grey: #9e9e9e;
  --yellow: #ffed00;
  --orange: #ff9100;
}
/* #endregion */

/* #region basics */
body {
  font-family: Roboto, sans-serif;
}
.monospace {
  font-family: Consolas, monaco, monospace;
}
.bold {
  font-weight: bold;
}
.normal {
  font-weight: normal;
}
.force-gpu {
  transform: translateZ(0px);
}
.hyphens {
  hyphens: auto;
}

h1, h2, h3, h4, h5, h6, .uppercase {
  text-transform: uppercase;
}
.notransform {
  text-transform: none;
}

p,
.ui-notification > .message {
  line-height: 1.5;
  word-break: break-word;
}
p + p,
p + ul,
p + ol,
ul + p,
ol + p,
ul + ul,
li + li {
  margin-top: .5em;
}

a,
[ng-click],
[onclick] {
  cursor: pointer;
}
button[disabled] {
  cursor: not-allowed !important;
}
a:active,
a:hover {
  text-decoration: underline;
}
.md-button {
  padding-left: 1em;
  padding-right: 1em;
}
.md-button:not(.md-icon-button) md-icon {
  line-height: 22px;
}
.md-button.inline-button {
  margin-top: -20px;
  margin-bottom: -20px;
}
.md-button.md-fab {
  padding: 0;
}
.md-button.md-rounded {
  border-radius: 9999px;
}

small, .small {
  font-size: smaller;
}
.small-info {
  color: var(--grey);
  font-size: 10px;
  font-weight: bold;
  line-height: 1;
  text-transform: uppercase;
}

.left {
  text-align: start;
}
.center {
  text-align: center;
}
.right {
  text-align: end;
}
.justify {
  text-align: justify;
}

.centerhv {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.float-left {
  float: left;
}
.float-right {
  float: right;
}
.clear::after,
.clearfix::after {
  display: block;
  clear: both;
  content: '';
}

.nowrap {
  white-space: nowrap;
}
.pre-line {
  white-space: pre-line;
}
.pre-wrap {
  white-space: pre-wrap;
}

hr {
  border: 0;
  clear: both;
  height: 1px;
  width: 100%;
}
hr.large {
  margin: 1.5em 0;
}
hr:last-child {
  margin-bottom: 0;
}
.ui-notification > .message hr {
  background-color: rgba(255, 255, 255, .3);
}

.full-width {
  width: -webkit-fill-available;
  width: -moz-available;
  width: stretch;
}

.fit-content {
  min-width: fit-content;
  min-height: fit-content;
}

.fliph {
  transform: scaleX(-1);
  filter: FlipH;
}
.flipv {
  transform: scaleY(-1);
  filter: FlipV;
}

.indent {
  padding-left: 1em;
}

.more-hover {
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.more-hover:hover,
td:hover > .more-hover {
  white-space: normal;
}

.anonymized::after {
  content: 'Anonymisiert' !important;
  font-style: italic;
}

.warn-block {
  border: 1px solid;
  border-radius: .5em;
  padding: .5em;
}

img.grayscale, img.greyscale {
  filter: grayscale(100%);
}

.success {
  color: var(--success);
}
.warning {
  color: var(--warning);
}

.gross::after {
  content: '(brutto)';
  text-transform: none;
}

@media print {
  .no-print,
  .no-print *,
  .global-button {
    display: none !important;
  }
}
/* #endregion */

/* #region responsive margins */
.mg-tl-gt-xs,
.mg-tl-gt-sm,
.mg-tl-gt-md,
.mg-tl-gt-lg {
  margin-top: .5em;
}
.mg-br-gt-xs,
.mg-br-gt-sm,
.mg-br-gt-md,
.mg-br-gt-lg {
  margin-bottom: .5em;
}
@media (min-width: 600px) {
  .mg-tl-gt-xs {
    margin-top: 0;
    margin-left: .5em;
  }
  .mg-br-gt-xs {
    margin-bottom: 0;
    margin-right: .5em;
  }
}
@media (min-width: 960px) {
  .mg-tl-gt-sm {
    margin-top: 0;
    margin-left: .5em;
  }
  .mg-br-gt-sm {
    margin-bottom: 0;
    margin-right: .5em;
  }
}
@media (min-width: 1280px) {
  .mg-tl-gt-md {
    margin-top: 0;
    margin-left: .5em;
  }
  .mg-br-gt-md {
    margin-bottom: 0;
    margin-right: .5em;
  }
}
@media (min-width: 1920px) {
  .mg-tl-gt-lg {
    margin-top: 0;
    margin-left: .5em;
  }
  .mg-br-gt-lg {
    margin-bottom: 0;
    margin-right: .5em;
  }
}
/* #endregion */

/* #region mdDialog */
md-dialog md-dialog-content .dialog-info {
  float: left;
  margin-right: .25em;
}
md-dialog md-dialog-content h2 {
  margin-bottom: 1em;
}
md-dialog md-dialog-content .md-dialog-content-body {
  max-width: 1000px;
}

@media (min-width: 960px) {
  .md-dialog-content ~ .md-dialog-content {
    border-left-width: 1px;
    border-left-style: solid;
  }
}

md-dialog.dialog-iframe {
  width: 100%;
  height: 100%;
}
md-dialog.dialog-iframe md-dialog-content {
  height: 100%;
  position: relative;
  overflow: hidden;
}
md-dialog.dialog-iframe md-dialog-content iframe {
  position: absolute;
  width: 100%;
  height: 100%;
}
md-dialog.dialog-iframe md-dialog-content img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.labels-dialog .md-dialog-content img {
  display: block;
  height: 70px;
  max-width: 140px;
  margin-block-end: 24px;
  margin-inline: auto;
  object-fit: contain;
}
.labels-dialog .actions {
  margin-block-start: 24px;
  text-align: center;
}
/* #endregion */

/* #region app toolbar */
md-toolbar#toolbar-header {
  z-index: 70;
}
.md-toolbar-tools .logo {
  content: url('/img/ottobot.png');
  height: 100%;
  margin-right: 16px;
  transition: all 250ms;
}
.md-toolbar-tools md-menu#menu-account > .md-button {
  text-transform: none;
}

/* info */
md-toolbar#toolbar-header .info {
  border-radius: 2px;
  font-size: initial;
  left: 50%;
  padding: 2px 8px 2px 4px;
  position: absolute;
  transform: translateX(-50%);
}
md-toolbar#toolbar-header .info p {
  display: inline-block;
  vertical-align: middle;
}
md-toolbar#toolbar-header .info p b {
  text-transform: uppercase;
}
/* #endregion */

/* #region left sidenav (navigation) */
.md-sidenav-left {
  min-width: unset;
  width: unset;
}
.md-sidenav-left.md-locked-open {
  z-index: 58;
}
.md-sidenav-left .nav-main {
  margin-top: 1em;
}
.md-sidenav-left .nav-main:last-child {
  margin-bottom: 1em;
}
.md-sidenav-left .nav > a {
  border-radius: 0;
  display: block;
  margin: 0;
  padding: 0 12px;
  text-align: left;
}
.md-sidenav-left .nav-main > a {
  font-weight: bold;
}
.md-sidenav-left .nav-sub > a {
  padding-left: 48px;
}
.md-sidenav-left .nav-main > a md-icon:not(.chevron) {
  margin-right: 12px;
}
.md-sidenav-left md-icon.chevron {
  position: absolute;
  top: 9px;
  left: 285px;
  margin: 0;
}
.md-sidenav-left .nav-main .nav-sub {
  overflow: hidden;
  height: 0;
  transition: height 250ms ease 250ms;
}
.md-sidenav-left .nav-main:hover .nav-sub,
.md-sidenav-left .nav-main.open .nav-sub {
  height: 36px;
}

/* collapsible navigation */
.md-sidenav-left md-content {
  overflow-x: hidden;
}
@-moz-document url-prefix() {
  .md-sidenav-left md-content {
    overflow-y: scroll;
  }
}
#navigation {
  width: 320px;
  overflow-x: hidden;
}
#navigation.collapse {
  width: 48px;
}
#navigation.collapse .nav-sub {
  display: none;
}
#navigation .md-button {
  text-transform: none;
  font-weight: normal;
}
#navigation .active > .md-button {
  font-weight: bold;
}
#collapse-navigation {
  min-height: 48px;
}
#collapse-navigation .md-button {
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 0 4px;
  min-width: initial;
}
/* #endregion */

/* #region content */
ng-include {
  display: block;
}
md-content.main {
  overflow-x: hidden;
}
md-content.main > div {
  height: 100%;
}
md-content.main > md-content,
md-content.main > :not(md-content) > md-content {
  margin: 0 !important;
  padding: 1.5em;
}
body.reporting md-content.main > md-content,
body.reporting md-content.main > :not(md-content) > md-content {
  padding-bottom: 7.5em;
}

[ui-view]:empty {
  display: none;
}

/* section */
section:not(:last-child),
[ui-view]:not([ui-view=""]):not(:last-child),
.section-margin {
  margin-bottom: 1.5em;
}
section > h2,
section > h3,
.section .section-head td {
  margin-top: 0 !important;
  border-bottom: 4px solid;
  padding-bottom: 4px;
}
section > h2 > .actions,
section > h3 > .actions {
  float: right;
  margin-top: -11px;
}
section .border-progress {
  height: 4px;
  margin-top: -4px !important;
  overflow: hidden;
}
section > :not(:first-child):not(table.section) {
  margin: .5em 0 0 0;
}
section > :not(h2):not(h3):not(.nobox):not(.boxgrid):not(table.section),
section .box,
.section .section-box td {
  /* .md-whiteframe-1dp */
  box-shadow:
    0 1px 3px  0   rgb(0 0 0 / 20%),
    0 1px 1px  0   rgb(0 0 0 / 14%),
    0 2px 1px -1px rgb(0 0 0 / 12%);
  box-sizing: border-box;
  overflow-x: auto;
  padding: .75em;
}
.box-gutter {
  width: 1.5em;
}
.section .section-box td {
  padding-left: 0;
  padding-right: 5px; /* = ::before width */
}
section .box {
  display: block;
  position: relative;
}
section .boxgrid {
  margin-inline: -8px !important;
  padding: 0;
}
section .boxgrid .box {
  height: 100%;
}
section h4 {
  margin-bottom: .75em;
}
section hr {
  margin: .75em 0;
}

/* .section */
.section {
  border-spacing: 0 .5em;
  empty-cells: show;
  width: 100%;
}
.section .section-head td {
  font-weight: bold;
  padding: 14px 0 1px 0;
  text-transform: uppercase;
}
.section .section-box td {
  position: relative;
}
.section .section-box td::before {
  pointer-events: none;
  position: absolute;
  content: ' ';
  top: 0;
  right: 0;
  width: 5px;
  height: 100%;
  z-index: 1;
}

#locations-map {
  height: 75vh;
}
#transmissions-map {
  height: 65vh;
  width: 75vw;
}
@media (max-width: 959px) {
  #transmissions-map {
    height: 80vh;
    width: 100%;
  }
}

/* #endregion */

/* #region right sidenav */
.md-sidenav-right {
  max-width: 80%;
  width: 1000px;
}
@media (max-width: 599px), print {
  .md-sidenav-right {
    max-width: 100%;
    width: 100%;
  }
}
.md-sidenav-right > div:first-child > .md-button {
  border: 2px solid;
  margin: 0;
  padding: 6px;
  position: fixed;
  top: calc(1.5em - 8px);
  right: 1.5em;
  z-index: 1;
}
.md-sidenav-right md-content {
  box-sizing: border-box;
  overflow: auto;
  padding: 1.5em;
  position: absolute;
  width: 100%;
  height: 100%;
}
.md-sidenav-right md-content:not(.font-size-normal) {
  font-size: 14px;
}
.md-sidenav-right h2 {
  font-weight: bold;
  line-height: normal;
  text-transform: none;
}
.md-sidenav-right h2 > * {
  font-weight: normal;
}
.md-sidenav-right hr {
  margin: 1.5em 0;
}
.md-sidenav-right .sidenav-toolbar {
  line-height: 2;
}
.md-sidenav-right .sidenav-toolbar > * {
  margin: 0 1em;
}
.md-sidenav-right .sidenav-toolbar img {
  vertical-align: baseline;
}

/* multiple sidenavs (LIFO) */
md-backdrop.md-sidenav-backdrop:first-child {
  z-index: 60;
}
/* #endregion */

/* #region lists */
ul,
ol {
  padding-left: 32px;
}
ul li,
ol li {
  display: list-item;
  line-height: 1.5;
}
ul li {
  list-style-type: square;
}
li.arrow {
  list-style-type: none;
}
li.arrow::before {
  content: '\21D2';
  left: 9px;
  position: absolute;
}
dt, dd {
  line-height: normal;
  min-height: 1em;
}
dt {
  float: left;
  width: 160px;
  overflow: hidden;
  font-weight: bold;
}
dt::after {
  content: ':';
}
dd {
  margin-left: 176px;
  margin-bottom: .5em;
  font-weight: normal;
}
dd:last-of-type {
  margin-bottom: 0;
}
dd::after {
  clear: both;
  content: '';
  display: block;
}
dd > ul {
  padding-left: 0;
}
dd > ul li {
  display: block;
}
dd > ul li:not(:last-child) {
  margin-bottom: 0 !important;
}
dl.d50 > dt {
  width: 50%;
}
dl.d50 > dd {
  margin-left: 50%;
}

@media (max-width: 599px) {
  dt {
    float: inherit;
    width: inherit !important;
  }
  dd {
    margin-top: .5em;
    margin-left: 1em !important;
  }
}

/* #endregion */

/* #region forms */
input[type="number"] {
  text-align: right;
}
input,
select {
  text-overflow: ellipsis;
}
fieldset {
  border: 1px solid;
  margin: 0 0 .75em 0;
  min-inline-size: auto; /* not min-content, content could stretch */
}
fieldset:last-child {
  margin-bottom: 0;
}
fieldset > legend {
  padding: 0 .25em;
}
md-input-container .md-errors-spacer:last-child,
md-input-container.md-icon-right .md-errors-spacer:nth-last-child(2)
{
  min-height: 0;
}
.no-errors-spacer md-input-container .md-errors-spacer {
  display: none;
}
md-autocomplete.no-errors-spacer md-autocomplete-wrap md-progress-linear.md-inline {
  bottom: calc(40px - 24px);
}
md-input-container .input-hint {
  color: var(--grey);
  position: relative;
  overflow: hidden;
  clear: left;
  font-size: 12px;
  line-height: 14px;
  margin: 0;
  padding: 5px 5px 0 0;
}
md-input-container.slim {
  margin: 0;
}

.filter-gap {
  gap: 1rem;
}

/* checkbox */
md-checkbox.nomargin,
md-checkbox.pure {
  margin-bottom: 0;
}
md-checkbox.pure .md-label {
  margin-left: 0;
}

/* switch */
md-switch.nomargin {
  margin: 0;
}

/* button group */
.btn-group > .md-button:not(.md-raised) {
  border: 1px solid;
}
.btn-group > :first-child:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  margin-right: 0;
}
.btn-group > :not(:first-child):not(:last-child) {
  border-radius: 0;
  border-left-width: 0;
  margin-right: 0;
  margin-left: 0;
}
.btn-group > :last-child:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left-width: 0;
  margin-left: 0;
}
@media (max-width: 599px) {
  .btn-group .md-button > .ng-scope:nth-child(2):not(md-icon) {
    display: none;
  }
}

/* hide input arrows (Chrome, Safari, Edge, Opera) */
input.clean::-webkit-outer-spin-button,
input.clean::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* hide input arrows (Firefox) */
input.clean[type=number] {
  -moz-appearance: textfield;
}

/* show time input clock (calendar picker) just on hover */
input[type=time]::-webkit-calendar-picker-indicator {
  filter: opacity(0);
}
input[type=time]:hover::-webkit-calendar-picker-indicator {
  filter: unset;
}

/* fix open datepicker */
.md-datepicker-open {
  min-height: 30px;
}

/* fix select value with flex */
.md-block .md-select-value > span:not(.md-select-icon) {
  width: 0;
}

/* fix md-contact-chips */
.md-contact-suggestion img {
  margin-block: 4px 4px;
}
.md-contact-suggestion img,
.md-contact-chips .md-chips md-chip .md-contact-avatar img {
  vertical-align: baseline;
}
.md-contact-suggestion .md-contact-name {
  width: 150px;
}
/* #endregion */

/* #region icons */
md-icon svg path[fill^="#"],
md-icon svg path[style^="fill:"],
md-icon svg path[class] {
  fill: currentColor !important;
}
md-icon.active {
  position: relative;
}
md-icon.active::after {
  box-sizing: border-box;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  position: absolute;
  left: 8.5px;
  top: 3.75px;
  display: table;
  width: 7px;
  height: 12.5px;
  border-width: 2px;
  border-style: solid;
  border-top: 0;
  border-left: 0;
  content: '';
}
md-icon.inline,
md-icon.inline.md-24 {
  margin-top: -3px;
  margin-bottom: -3px;
  vertical-align: top;
}
md-icon.inline.md-18 {
  margin-top: 0;
  margin-bottom: 0;
}

.logo-small-square {
  background-color: white;
  border: 1px solid #ccc;
  box-sizing: border-box;
  object-fit: contain;
  width: 32px;
  height: 32px;
}
/* #endregion */

/* #region table */
.table-responsive {
  overflow-x: auto;
}
table td, table th {
  line-height: 1.5;
  text-align: left;
}
table .vtop {
  vertical-align: top;
}
table .vbottom {
  vertical-align: bottom;
}
table .trhover,
table .tdhover {
  visibility: hidden;
}
table tr:hover .trhover,
table td:hover .tdhover {
  visibility: visible;
}
/* #endregion */

/* #region table.datatable */
.datatable {
  border-spacing: 1px;
  empty-cells: show;
  font-size: 14px;
  width: 100%;
}
.datatable input,
.datatable select {
  box-sizing: border-box;
  width: 100%;
}
.datatable th,
.datatable td,
.datatable input:not(.md-input) {
  padding: 4px;
}
.datatable select {
  padding-top: 3px;
  padding-bottom: 3px;
}
.datatable td.actions .md-button {
  margin: 0;
}

/* head */
.datatable thead,
.section thead,
.tableheading {
  font-size: 12px;
  text-transform: uppercase;
}
.tableheading {
  font-weight: bold;
  margin: 5px;
}

/* head filter */
.datatable thead tr.filter td,
.datatable tr.empty td {
  padding: 0px;
}
.datatable thead tr.filter input {
  border: 1px solid;
}

/* body */
.datatable tbody tr {
  transition: background-color 250ms;
}
.datatable th.actions {
  width: 0;
}
.datatable td.actions {
  white-space: nowrap;
  width: 0;
}
.datatable .highlight {
  color: white;
}
.datatable .highlight-success {
  background-color: var(--success) !important;
}
.datatable .highlight-warning {
  background-color: var(--warning) !important;
}
.datatable .highlight-yellow {
  background-color: var(--yellow) !important;
  color: rgba(0,0,0,0.87);
}
.datatable .highlight-orange {
  background-color: var(--orange) !important;
}
.datatable .sortorder::after,
.datatable .stat-more::after {
  content: '\25b2';
}
.datatable .sortorder.reverse::after,
.datatable .stat-less::after {
  content: '\25bc';
}
.datatable .stat-equal::after {
  content: '\25c6';
}
.datatable .stat-good::after {
  color: var(--success);
}
.datatable .stat-bad::after {
  color: var(--warning);
}
.datatable .stat-neutral::after {
  color: var(--orange);
}

/* foot */
.datatable tfoot {
  font-weight: bold;
}

/* subtable */
.datatable .subtable {
  padding: 3px;
}
.datatable .subtable td {
  padding: 0px 4px;
}
.datatable .subtable > p {
  margin-left: 5px;
}

/* itemtable */
.itemtable td {
  vertical-align: top;
}
.itemtable tfoot tr.sum {
  font-size: 1.5em;
}
.itemtable tfoot tr:not(.sum) td {
  font-weight: normal;
}
/* #endregion */

/* #region global buttons */
.md-button.global-button {
  width: 80px;
  height: 80px;
  z-index: 59;
  margin: 0;
  position: fixed;
  bottom: 20px;
  right: 20px;
}
.md-button.global-button::after {
  content: '';
  display: block;
  position: absolute;
  width: 50%;
  height: 50%;
  background-color: white;
  top: 50%;
  left: 50%;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
}
.md-button.global-button.report-button md-icon,
.md-button.global-button.customer-support-button md-icon {
  width: 56px;
  height: 56px;
}
.md-button.global-button.customer-support-button {
  border-radius: 0;
}
.md-button.global-button.customer-support-button::before {
  content: '';
  display: block;
  position: absolute;
  width: 30px;
  height: 30px;
  background-color: white;
  top: 29px;
  left: 51px;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
}
.md-button.global-button.customer-support-button::after {
  top: 55px;
  left: 25px;
}
.md-button.global-button.panic-button {
  right: 120px;
}
.md-button.global-button.panic-button md-icon {
  width: 64px;
  height: 64px;
  font-size: 64px;
}
/* #endregion */

/* #region login */
md-content.login > * {
  width: 364px;
}
md-content.login md-card-title {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
md-content.login md-card-content {
  padding: 1em;
}
.yubikey.yubikey-otp {
  position: absolute;
  width: 0;
  height: 0;
  top: -10000px;
  left: -10000px;
}
/* #endregion */

/* #region charts */
div.google-visualization-tooltip {
  color: black;
  font-size: 14px;
}

.chart-daily,
.chart-sales {
  position: relative;
}
.chart-daily > md-switch,
.chart-sales > md-select {
  position: absolute;
  top: 12px;
  right: 6%;
  margin: 0;
  z-index: 1;
}
/* #endregion */

/* #region sortable */
.grab-handle {
  cursor: grab;
}
.sortable-entry {
  border: 1px solid;
  margin-bottom: -1px;
  padding: 1em;
}
.sortable-entry:first-child {
  border-top-left-radius: .5em;
  border-top-right-radius: .5em;
}
.sortable-entry:last-child {
  margin-bottom: 0;
  border-bottom-left-radius: .5em;
  border-bottom-right-radius: .5em;
}
.sortable-chosen,
.sortable-ghost {
  /* .md-whiteframe-8dp */
  box-shadow:
    0px 5px 5px -3px rgba(0, 0, 0, 0.2),
    0px 8px 10px 1px rgba(0, 0, 0, 0.14),
    0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  transform: translateZ(0px);
}
.sortable-drag {
  background-color: initial !important;
  border: 1px solid transparent !important;
  box-shadow: none !important;
}
.sortable-drag > * {
  visibility: hidden;
}
/* #endregion */

/* #region vytal */
.vytal {
  color: #1cc282 !important;
}
.vytal-bg {
  background-color: #1cc282 !important;
}
/* #endregion */

/* #region booking */
.booking-buttons {
  margin-block-start: 2.5em;
}
.booking-buttons > .fillin {
  background: linear-gradient(to right, rgb(96,169,23) 50%, transparent 50%) right;
  background-size: 200% 100%;
  animation: background-r2l 2.5s ease-in;
}

.bookings-list {
  margin-inline: -1rem;
}
.bookings-list section {
  padding-inline: 1rem;
  margin-block-end: 1.5rem;
}

.bookings-list .table-responsive {
  height: calc(100% - 35px);
}

:root {
  --booking-image-ratio: 0.625;
}
.booking-tile {
  --booking-tile-width: 130px;
  --booking-transition-duration: 200ms;
  --booking-transition-function: ease-in-out;

  user-select: none;

  display: inline-block;
  vertical-align: top;

  width: var(--booking-tile-width);
  margin: 1rem;

  transition: opacity var(--booking-transition-duration) var(--booking-transition-function);
}
.booking-tile-2x {
  width: calc(2 * (var(--booking-tile-width) + 1rem));
  max-width: calc(100% - 2rem);
}
.booking-tile-image {
  --booking-tile-image-width: 115px;

  display: flex;

  width: var(--booking-tile-image-width);
  margin: calc((var(--booking-tile-width) - var(--booking-tile-image-width)) / var(--booking-image-ratio) / 2) calc((var(--booking-tile-width) - var(--booking-tile-image-width)) / 2);
  aspect-ratio: var(--booking-image-ratio);

  pointer-events: none;
  transition: all var(--booking-transition-duration) var(--booking-transition-function);
}
.booking-tile-image img {
  object-fit: cover;
  width: 100%;
}
.booking-tile-image .material-icons,
.booking-tile-phone {
  transition: transform var(--booking-transition-duration) var(--booking-transition-function);
}
.booking-tile-title {
  padding-block-start: .5rem;
  padding-inline: .25rem;

  text-transform: uppercase;

  transition: color var(--booking-transition-duration) var(--booking-transition-function);
}
.booking-tile-phone {
  border-radius: 1rem;
  padding: 1rem;
  font-size: small;
}

.booking-tile.selected {
  --booking-transition-duration: 250ms;
}
.booking-tile.selected .booking-tile-image {
  width: var(--booking-tile-width);
  margin: 0px;
}
.booking-tile.selected .booking-tile-image .material-icons,
.booking-tile.selected .booking-tile-phone {
  transform: scale(1.1);
}
.booking-tile.inactive {
  opacity: .25;
}
.booking-tile.disabled {
  cursor: not-allowed;
  filter: grayscale(1);
  opacity: .25;
}

.booking-calendar {
  width: 100%;
  min-width: 490px;
  max-width: 630px;
  border-spacing: .5rem 1rem;
}
.booking-calendar th,
.booking-calendar td {
  width: calc(100% / 7);
  text-align: center;
}
.booking-calendar th {
  font-size: small;
}
.booking-calendar td {
  --booking-calendar-border-width: 1px;

  padding: calc(4px - var(--booking-calendar-border-width));
  border: var(--booking-calendar-border-width) solid;

  font-size: large;
  vertical-align: top;
}
.booking-calendar .today {
  --booking-calendar-border-width: 3px;
}
.booking-calendar .disabled {
  cursor: not-allowed;
  opacity: .25;
}
.booking-calendar-date {
  padding-block: .5rem;
  line-height: .9;
}
.booking-calendar-day {
  font-weight: bold;
}
.booking-calendar-month {
  text-transform: uppercase;
  font-size: small;
}
.booking-calendar-campaign {
  font-size: small;
  font-weight: bold;
}

.booking-check img {
  display: inline-block;

  width: 400px;
  max-width: 100%;
  aspect-ratio: var(--booking-image-ratio);
  pointer-events: none;
}
.booking-check .booking-tile-phone {
  width: 400px;
  max-width: 100%;
}

.booking-info-wrapper {
  display: grid;
  grid-template-rows: 1fr auto;

  min-height: 24rem;
  margin-inline-start: 28px;
}
.booking-info-wrapper dt {
  width: 180px;
}
.booking-info-wrapper dd {
  margin-left: 196px;
}

.booking-result {
  width: 100%;
  min-height: 40vh;
}
/* #endregion */
